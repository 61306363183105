import React from "react";
import { useTheme, useMediaQuery, Box, Stack, Typography, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as S from "./App.styles";

const Resume = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledPaper elevation={isMobile ? 0 : 1} sx={{ display: "flex", justifyItems: "center" }}>
      <Stack width="100%" spacing={2} {...(isMobile ? { p: 2 } : {})}>
        <Stack display="flex" direction={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 0} width="100%" flexWrap>
          <Box display="flex" justifyContent="center">
            <img src="avatar.jpg" alt="Ethan Collins" height="300px" width="225px" />
          </Box>
          <Stack width="100%" alignItems="center" justifyContent="center" marginRight="-1rem">
            <Typography variant="h3" component="h2" noWrap>
              Ethan Collins
            </Typography>
            <Typography variant="h5" component="p" fontWeight="300" noWrap>
              Technology Consultant
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="p" component="p" fontSize="1.25rem" fontWeight="400">
          Technology consultant with four years of experience, specializing in web and cloud development. At IBM,
          I&apos;ve worked on complex engagements for major Automotive and Oil and Gas clients, demonstrating capability
          in developing and delivering AWS cloud-based full-stack applications. I have experience coordinating with
          third party vendors, leading development teams, and setting development standards. Skilled in navigating the
          challenges of fast-paced environments, I excel in turning ambiguous requirements into actionable, efficient
          solutions that drive client success.
        </Typography>
        <Box display="flex" justifyContent="center">
          <IconButton
            color="primary"
            onClick={() => window.open("https://www.linkedin.com/in/ethan-t-collins", "_blank")}
          >
            <LinkedInIcon fontSize="large" />
          </IconButton>
        </Box>
      </Stack>
    </S.StyledPaper>
  );
};

export default Resume;
